<template>
  <v-carousel delimiter-icon="mdi-record-circle">
    <v-carousel-item
      reverse-transition="fade-transition"
      transition="fade-transition"
    >
      <v-row
        class="d-flex align-center justify-center fill-height px-16 black--text"
      >
        <v-col cols="12" sm="12" md="6">
          <div class="display-2">
            <v-img
              ref="img_content"
              height="300"
              :eager="true"
              :src="program.src"
            ></v-img>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <div class="text-left subtitle-1">
            <p class="text-h6">{{ program.title }}</p>
            <div class="divider"></div>
            <p>
              {{ program.text }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-carousel-item>
  </v-carousel>
</template>
<script>
export default {
  name: "PreviewProgram",
  data: () => ({}),
  props: { program: Object },
};
</script>

<style></style>
